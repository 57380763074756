
<script setup>
import {reactive, toRefs, watch, ref, onMounted} from "vue";
import {messageCreate} from '@/api/index'
import { Message } from 'element-ui';
import QRcode from 'qrcodejs2'

// 获取props
const  props= defineProps({
    isFormSubmit:{
        type:Boolean,
        default:false
    }
})

const state = reactive({
    ruleForm:{
        name:'1',
        phone:'1',
        content:'1'
    },
    rules:{}
})

const emit = defineEmits(['isFormSubmitFun']);
watch(
  () => props.isFormSubmit,
  (val) => {
    if(val){

    }

    console.log(props.isFormSubmit,val,989777);
  },
)

const refForm = ref(null)

// 我在点击提交了
const handleSubmit =()=>{
      (refForm.value).validate((valid) => {
        console.log(state.ruleForm,99);

        messageCreate(state.ruleForm).then(s=>{
            $message.success('留言成功');
            emit('isFormSubmitFun');
        })
      })
}

const handleClose=()=>{
    emit('isFormSubmitFun',false);
}


const {ruleForm,rules} = toRefs(state)


onMounted(()=>{
    // setTimeout(()=>{
    //   new QRcode('qrCodeMessage', {
    //         width: 200, // 生成二维码宽度
    //         height: 200, // 生成二维码高度
    //         text: '微信互动留言的地址', // 二维码地址或文本，如果是网址扫码后会跳转网址
    //         colorDark: '#000',
    //         colorLight: '#fff'
    //     })
    // },10)
})


</script>

<template>
    <div class="form tc">
<!--        <el-form :model="ruleForm" ref="refForm" label-width="80px" class="demo-ruleForm">-->
<!--            <el-form-item label="姓名" prop="name">-->
<!--                <el-input  v-model="ruleForm.name"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="电话" prop="phone">-->
<!--                <el-input   v-model="ruleForm.phone"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item label="内容" prop="text">-->
<!--                <el-input  type="textarea" :rows="8" resize="none" v-model="ruleForm.content"></el-input>-->
<!--            </el-form-item>-->
<!--            <el-form-item>-->
<!--                <el-button  class="submit-btn" type="primary" @click="handleSubmit()">提交</el-button>-->
<!--                <el-button  class="cancel-btn" @click="handleClose()">关闭</el-button>-->
<!--            </el-form-item>-->
<!--        </el-form>-->
<!--        <div id="qrCodeMessage"></div>-->
        <img src="@/assets/imgs/code.jpg">


    </div>
</template>
<style lang="scss" scoped>
::v-deep .el-form-item__label{
    font-size: 30px;
    color:#1c1c1c;
}
.form{
    //width: 750px;
    //margin:35px auto;
}
.cancel-btn{
    width:209px;
    height:66px;
    border:1px solid #a8a8a8;
    text-align: center;
    color:#202020;
    font-size:30px;
    background:#efefef;
    box-sizing: border-box;
    line-height: 66px;
     padding: 0;
}
.submit-btn{
    width:209px;
    height:66px;
    background:#ffffff;
    border:1px solid #a8a8a8;
    box-sizing: border-box;
    color:#202020;
    font-size:30px;
     line-height: 66px;
     padding: 0;
}
::v-deep img{
    margin: auto;
}

img{
    width: 200px;
    height: 200px;
}

</style>
