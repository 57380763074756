<template>
  <div class="home">
    <!--  banner    -->
    <div class="banner-box">
        <el-carousel  :height="bannerImgHeight+'px'" :interval="5000"  indicator-position="none">
            <el-carousel-item  v-for="(item,i) in bannerLists" :key="i">
                <div ref="bannerImg">
                     <img class="banner-img cursor-pointer" @click="handleBannerDetail(item)"   :src="item.picUrl">
                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
    <!-- 资讯   -->
    <div class="container-box flex flex-space-center ">
      <div class="information-left">
        <div class="information-left-tit flex flex-space-between">
          <label class="font-style-s">【文博资讯】</label>
          <i class="cursor-pointer" @click="handleZX()">更多</i>
        </div>
        <ul>
          <li v-for="item in zxLists" :key="item.id" @click='handleZxDetail(item)' class="flex flex-align-center">
            <div class="zx-left">
              <h4>{{item.month}}.{{item.day}}</h4>
              <p>{{item.year}}</p>
            </div>
            <i/>
            <div class="line-clamp1 information-text">{{item.title}}</div>
          </li>
        </ul>
      </div>
      <div class="information-right">
        <h3 class="font-style-s">【免费服务内容】</h3>
        <div class="information-right-text">
          <p>1. 历史文物展、临时展览服务</p>
          <p>2. 接待、登记、各种咨询、解答服务</p>
          <p>3. 展厅内导览、讲解服务</p>
          <p>4. 机动车停放服务。</p>
          <p>5. 免费发放博物馆宣传手册和文物法律、法规宣传资料</p>
          <p>6. 机对全县各级文物保护机构、文物管理人员定期开展文物保护知识培训和指导</p>
          <p>7. 节假日、周末提供免费开放服务</p>
          <p>8.
            8小时以外开展文物宣传，普及文物保护知识、宣传博物馆免费开放服务内容</p>
          <p>9. 提供寄存包裹、随身物品服务</p>
        </div>
      </div>
    </div>
    <!--  图片集  -->
    <div class="img-box flex flex-space-between flex-space-between tc">
     <img v-for="i in 11" :key="i" :src="require(`@/assets/imgs/${i}.png`)" />
    </div>
    <!-- 【馆藏文物】 -->
      <div class="cultural-relic tc">
          <h3 class="font-style-s">【馆藏文物】</h3>
          <div class="cultural-relic-lists">
              <label v-for="(keys,i) in culturalRelicLists" :key="i"  :class="culturalRelic === keys.classification && 'checked'" @click="handleCulturalRelic(keys)">{{keys.classificationName}}</label>
          </div>
          <!--    文物輪播      -->
          <div class="swiper-box">
              <el-carousel class="swiper-lists" height="656px" loop indicator-position="none" :autoplay="false">
                  <el-carousel-item v-for="(item,u) in wwLists"  :key="u">
                      <div class="swiper-lists-box  flex ">
                          <div v-for="(key,i) in item" :key="i" class="label block flex flex-align-center">
                            <router-link :to="'/cultural-relics/detail?id='+key.id">
                                <img :src="key.cover"><i>{{key.title}}</i>
                            </router-link>
                          </div>
                      </div>
                  </el-carousel-item>
              </el-carousel>
          </div>

          <!--    活动    -->
          <div class="active-box flex">
              <div class="active-list active-bg1" @click="goNextPage('/information')">
                  <p class="font-style-s">文博资讯</p>
                  <router-link class="a" to="/information">查看详情</router-link>
              </div>
              <div class="active-list active-bg2" @click="goNextPage('/cultural-relics')">
                  <p class="font-style-s">展览活动</p>
                  <router-link class="a" to="/cultural-relics">查看详情</router-link>
              </div>
              <div class="active-list active-bg3" @click="goNextPage('/cultural-creative')">
                  <p class="font-style-s">文创商城</p>
                  <router-link class="a" to="/cultural-creative">查看详情</router-link>
              </div>
              <div class="active-list active-bg4" @click="goNextPage('/exhibition')">
                  <p class="font-style-s">展览展馆</p>
                  <router-link class="a" to="/exhibition">查看详情</router-link>
              </div>
          </div>

          <!--    开放时间        -->
          <div class="development-time pr">
              <h3 class="font-style-s">【开放时间】</h3>
              <div class="development-time-text">
                  <p>周二至周五错时开放30分钟</p>
                  <p>周末和重要节庆日延时开放2小时，晚上：19:00-21:00</p>
              </div>
              <img class="img" src="@/assets/imgs/Group1183.png">
              <img class="img-r pa" src="@/assets/imgs/Rectangle1426.png">
              <img class="img-l pa" src="@/assets/imgs/Rectangle1427.png">
          </div>


      </div>


  </div>
</template>
<script setup>
// 响应式状态
import {onMounted, reactive, ref, toRefs ,computed,nextTick} from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import {articleLists, classification, antiqueLists, getHomeBanner} from '@/api/index'
import moment from 'moment'

 const router = useRouter()

const state = reactive(({
    culturalRelicLists:[
        {classificationName:'铜钱',classification:1},
        {classificationName:'瓷器',classification:2},
        {classificationName:'青铜器',classification:3},
        {classificationName:'铜器',classification:4},
        {classificationName:'银器',classification:5},
        {classificationName:'其他',classification:6},
    ],
     culturalRelic:1,
    zxLists:[],
    wwLists:[],
    bannerLists:[],
    bannerImgHeight:947
}))



// 跳转资讯
const handleZX=()=>{
  router.push('/information')
}
// 跳转资讯详情
const handleZxDetail=(item)=>{
    if(item.wechatUrl){
        window.open(item.wechatUrl,'_blank')
    }else {
        router.push('/information/detail?id='+item.id)
    }

}

const goNextPage=(url)=>{
    router.push(url)
}

// 获取资讯列表
const getZxLists=()=>{
    articleLists({
      pageNo:1,
      pageSize:5, categoryId:2
    }).then(({data})=>{
        const _data = data?.list || []

        _data.forEach(element => {
            element.year = moment(element.createTime).format('YYYY')
            element.month = moment(element.createTime).format('MM')
            element.day = moment(element.createTime).format('DD')
        });

        state.zxLists = _data
    })

}


// 获取文物分类
const getWwTypes = ()=>{

  classification().then(s=>{
    state.culturalRelicLists = s.data || []
      if(s.data.length > 0){
        state.culturalRelic = s.data[0].classification
          getWwLists()
      }
  })
}

const handleCulturalRelic=(item)=>{
    if(item.classification === state.culturalRelic) return
  state.culturalRelic = item.classification
  getWwLists()
}

function chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
}

// 获取文物列表
const getWwLists=()=>{
    antiqueLists({
      pageNo:1,
      pageSize:9,
      classification:state.culturalRelic
    }).then(({data})=>{
        const _data = chunkArray(data?.list || [] , 3)

        state.wwLists = _data
    })

}
const bannerImg = ref(null)
const getBanner =()=>{
    getHomeBanner({position:1}).then(({data})=>{
        state.bannerLists = data
    })
}

const handleBannerDetail=(item)=>{
    router.push(item.url)
}

const {zxLists,wwLists,culturalRelicLists,culturalRelic,bannerLists,bannerImgHeight} = toRefs(state)


// 生命周期钩子
onMounted(() => {
  getZxLists()
  getWwTypes()
  getBanner()
    // 获取图片的高度
    setTimeout(()=>{
        state.bannerImgHeight=bannerImg.value[0].offsetHeight
    },500)

})
</script>
<style scoped lang="scss">
.banner-box {
  width: 100%;
    min-height: 300px;
    margin-top: -109px;
    .banner-img{
        width: 100%;
        //height: 938px;
        //height: 100%;
    }
}

.container-box {
  margin: 33px auto;

  > div {
    width: 775px;
    display: inline-block;
  }

  .information-left {
    background: url("~@/assets/imgs/bgtext.jpg");
      background-size: 100% 100%;
    padding: 32px 0;
    box-sizing: border-box;

    &-tit {
      font-weight: 700;
      color: #ffffff;
      font-size: 36px;
      padding: 0 40px 0 20px;

      i {
        font-style: normal;
        width: 98px;
        height: 40px;
        border: 1px solid #bebebe;
        text-align: center;
        line-height: 40px;
        color: #ffffff;
        font-size: 24px;
        font-weight: normal;
      }
    }

    ul {
      margin-top: 65px;
      color: #ffffff;
      padding: 0px 40px;

      li {
        padding-bottom: 15px;
        border-bottom: 1px dotted rgba(255, 255, 255, .4);
        padding-top: 15px;

        .information-text {
          width: calc(100% - 110px);
        }
      }

      .zx-left {
        h4 {
          font-weight: 700;
          color: #ffffff;
          font-size: 24px;
        }

        p {
          font-weight: 700;
          color: #ffffff;
          font-size: 14px;
        }
      }

      i {
        height: 40px;
        width: 1px;
        background: #ffffff;
        display: inline-block;
        margin: 0 38px;
      }
    }

  }
  .information-right{
    background:#ffffff;
    padding: 32px 0;
    box-sizing: border-box;
    h3{
      font-weight:700;
      color:#1c1c1c;
      font-size:36px;
      padding: 0 40px 0 25px;
      margin-bottom: 75px;
    }
    &-text{
      font-size:16px;
      line-height:30px;
      font-family:Source Han Sans CN;
      padding: 0px 40px;

      p{
        color:#rgba(28, 28, 28, .5);
      }
    }

  }
}

.img-box{
    width: 1550px;
    margin:0 auto 65PX;
  img{
    width:106px;
    height:106px;
  }
}

.cultural-relic{
    h3{
        font-weight:700;
        color:#1c1c1c;
        font-size:36px;
    }

    &-lists{
        margin: 37px auto;
        label{
            //width:98px;
            height:40px;
            padding: 0 20px;
            display: inline-block;
            margin: 0 19px;
            line-height: 40px;
            text-align: center;
            color:#414141;
            font-size:24px;
            cursor: pointer;
            background-image: url("~@/assets/imgs/ex3.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            background-position: center;

            &.checked{
                border-color:#8b3527 !important;
                background:#8b3527 ;
                color:#f6f6f6 !important;
            }

             &:hover{
                background-image: url("~@/assets/imgs/ex4.png");
                color:#8b3527;
                cursor: pointer;
            }
            &.active{
                background-image: url("~@/assets/imgs/ex2.png");
                color:#fff;
            }
    }
    }

    .swiper-box{
        margin-top: 207px;
        background: url("~@/assets/imgs/Group1166.png");
        height:582px;
        background-size: contain;
        width: 100%;

        ::v-deep .el-carousel__arrow--left{
            display: none !important;
        }
        ::v-deep .el-carousel__arrow--right{
            display: none !important;
        }
        .swiper-lists{
            width: 1611px;
            height:656px;
            overflow: hidden;
            margin: auto;
            position: relative;
            top: -140px;

            .swiper-lists-box{
                gap: 50px;
                .label{
                    width:507px;
                    height:656px;
                    background:url("~@/assets/imgs/Subtraction47.png") no-repeat;
                    background-size: 100%;
                    position: relative;
                    text-align: center;
                    cursor: pointer;
                    overflow: hidden;
                    //background: #ffffff;


                    &:hover{
                        img{
                            top: -50px;

                        }
                    }

                    img{
                        width: 100%;
                        max-height: 70%;
                        //background: #42b983;
                        margin: auto;
                        position: relative;
                        top: 0;
                        transition: all 0.25s ease;
                    }

                    i{
                        font-style: normal;
                        color:#16161a;
                        font-size:21px;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 74px;
                    }
                }
            }
        }
    }
}

.active-box{
    width: 1556px;
    height: 767px;
    margin:80px auto 142px;

    .active-list{
        width: 388px;
        height:716px;
        padding: 18px 33px;
        box-sizing: border-box;
        position: relative;
        background:  url("~@/assets/imgs/a1.png");
        background-size: contain;
        cursor: pointer;
        transition: all 0.25s ease;


        &.active-bg1{
            background:  url("~@/assets/imgs/a4.png");
        }&.active-bg2{
            background:  url("~@/assets/imgs/a3.png");
        }&.active-bg3{
            background:  url("~@/assets/imgs/a2.png");
        }&.active-bg4{
            background:  url("~@/assets/imgs/a1.png");
        }

        &:hover{
            height: 761px;

            .a{
                display: block !important;
            }
        }

        p{
            font-weight:700;
            color:#ffffff;
            font-size:34px;
            width:34px;
        }

        .a{
            width:130px;
            height:40px;
            border:1px solid rgba(#f6f6f6,.7);
            color:rgba(#f6f6f6,.7);
            font-size:24px;
            text-align: center;
            line-height: 40px;
            text-decoration: none;
            position: absolute;
            bottom: 24px;
            left: 33px;
            display: none;
        }
    }
}
.development-time{
    font-weight:700;
    color:#1c1c1c;
    font-size:36px;
    &-text{
        margin: 32px auto;
        color:#3b3b3b;
        font-size:16px;
        line-height: 25px;
    }
    .img{
      width: 1150px;
        margin: 29px auto 59px;
    }
    .img-r{
      width: 287px;
      height: 497px;
        right: 0;
        bottom: 0;
        background: #efefef;
    }.img-l{
      width: 287px;
      height: 497px;
      left: 0;
        bottom: 0;
        background: #efefef;
    }

}
</style>
